@import '/Users/danp3d/projects/x-functions/x-ui/src/mui_variables';@import '../../../_variables.sass';

.form {
  width: 100%;
  max-width: 500px;
  border: 1px solid $input-border-color;
  padding: 48px 48px 56px 48px;

  .header {
    margin-bottom: 24px;

    .logo {
      padding: 0;
      padding-bottom: 48px;
      display: flex;
      justify-content: center;
    }

    .title {
      font-size: 20px;
      text-align: center;
    }

    .subtitle {
      font-size: 14px;
      text-align: center;
    }
  }

  :global(.field):not(:last-child) {
    margin-bottom: 16px;

    label {
      font-weight: normal;
      font-style: normal;
      font-size: 16px;
      color: $text;
    }

    input {
      font-weight: 400;
      font-size: 16px;
      color: $grey-dark;
    }
  }

  .separator {
    width: 100%;
    height: 2px;
    border-bottom: 1px solid $grey-lighter;
    position: relative;
    margin: 24px 0px;

    &::before {
      content: 'or';
      display: block;
      position: absolute;
      width: 30px;
      background-color: $white;
      left: calc(50% - 15px);
      color: $grey-lighter;
      top: -12px;
      text-align: center;
    }
  }

  :global(.notification.is-danger).error {
    border: 1px solid $danger;
    color: $black;
  }
}


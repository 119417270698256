@import '/Users/danp3d/projects/x-functions/x-ui/src/mui_variables'
@import './_variables.sass'

.drawer
  width: $drawer-width

.body, .appBar
  @include animate-with-drawer

  &.appBar
    height: $appbar-height
    justify-content: center

  &.open
    @include shrink-drawer-open

.openDrawer
  &.hide
    display: none

.underAppBar
  margin-top: $appbar-height
  position: relative

@import '/Users/danp3d/projects/x-functions/x-ui/src/mui_variables';@import './_variables.sass';
@import '../node_modules/bulma/bulma.sass';
@import '../node_modules/codemirror/lib/codemirror.css';
@import '../node_modules/codemirror/addon/lint/lint.css';

*, *:before, *:after {
  box-sizing: border-box;
}

.dropdown-item {
  cursor: pointer !important;
}

.dropdown-item:hover {
  background-color: #EDEDED;
}

@import '~@aws-amplify/ui/dist/style.css';
